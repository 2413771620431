import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import PrivacyConsent from './PrivacyConsent';
import PrivacyView from 'components/Privacy/PrivacyView';
import Preloader from 'components/Preloader';
import * as routes from 'constants/routes';
import * as constants from 'components/Privacy/PrivacyConstants';
import { analytics, api, db, getCustomRoute, helpers } from 'actions';
import styles from 'components/Privacy/Privacy.module.css';
import staticTexts from 'texts';
import { authRef } from 'firebase-config/firebase';

/**
 * Main controller which provides the /privacy route.
 */
const Privacy = ({ newUser }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const privacy = useSelector((state) => state.privacyReducer.data);
  const [screen, setScreen] = useState(constants.SCREEN_LOADING);
  const [tab, setTab] = useState(constants.PRIVACY_POLICY);
  const accountData = useSelector((state) => state.accountState.account);
  const authUser = useSelector((state) => state.sessionState.authUser);

  //Start by assuming that all consents have already been granted
  //and then require any consents which are missing
  const [requiredConsents, setRequiredConsents] = useState({
    terms_of_service: false,
    privacy_policy: false,
  });

  //set the variable whichDocument to either "terms_of_service", "privacy_policy", or "both"
  const whichDocument = getWhichDocument();

  // Track Page event only once.
  useEffect(() => {
    if (authUser === false) {
      getCustomRoute(history);
    } else {
      analytics.page('Privacy Consent');
    }
  }, []);

  useEffect(() => {
    if (accountData && privacy && privacy !== 'empty') {
      // Do not prompt a newly registered user, because they already
      // saw the statement "by signing up you agree to the terms" on
      // the previous screen.
      if (newUser) {
        accept();
      }
      //Ask for consent if the user has not consented yet.
      else if (consentRequired(accountData, privacy)) {
        setScreen(constants.SCREEN_CONSENT_PROMPT);
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Wait till the need data has downloaded before trying to
    // evaluate the user
    if (privacy === 'empty') {
      dispatch(
        db.setErrorPage(
          'Error',
          'Empty privacy data',
          'error',
          false,
          'Close',
          routes.SIGN_OUT
        )
      );
    } else if (accountData && privacy) {
      if (helpers.privacyConsentGiven(accountData, privacy)) {
        getCustomRoute(history);
      }
    }
    // eslint-disable-next-line
  }, [accountData, privacy]);

  //These elements are shared between <PrivacyConsent/> and <PrivacyView/>
  //so we will define them here and pass them in as props.
  const header = <h1 className={styles.title}>{getHeaderText()}</h1>;
  const buttons = (
    <div className={styles.buttons}>
      <button
        data-id="privace-logout-button"
        className={styles.logout}
        onClick={logout}
      >
        Logout
      </button>
      <button
        data-id="privacy-accept-button"
        className={styles.accept}
        onClick={accept}
      >
        Accept
      </button>
    </div>
  );

  const components = {
    SCREEN_LOADING: <Preloader />,
    SCREEN_CONSENT_PROMPT: (
      <PrivacyConsent
        privacy={privacy}
        requiredConsents={requiredConsents}
        whichDocument={whichDocument}
        navigate={{ viewPrivacyPolicyFull, viewTermsFull, viewSummary }}
        styles={styles}
        header={header}
        buttons={buttons}
      />
    ),
    SCREEN_VIEW_DOCUMENTS: (
      <PrivacyView
        privacy={privacy}
        setScreen={setScreen}
        navigate={{ viewPrivacyPolicyFull, viewTermsFull, viewSummary }}
        tab={tab}
        styles={styles}
        header={header}
        buttons={buttons}
      />
    ),
  };

  return <div className={styles.privacy}>{components[screen]}</div>;

  //When a user accepts the privacy documents, we should store the consent
  //in firestore. We will store the accepted version number and time of
  //acceptance for each document.
  async function accept() {
    setScreen(constants.SCREEN_LOADING);
    const idToken = await authRef?.currentUser.getIdToken();
    api
      .memberUpdate(
        {
          legal_consent: {
            pp_timestamp: Date.now(),
            pp_version: privacy?.privacy_policy?.versionNumber,
            tos_timestamp: Date.now(),
            tos_version: privacy?.terms_of_service?.versionNumber,
          },
        },
        idToken,
        dispatch
      )
      .then((data) => {
        const { legal_consent } = data;
        analytics.track('Legal Consent Clickwrap Accepted', {
          context: {
            app: {
              name: 'AuthApp',
              namespace: 'components/Privacy/Consent',
              build: process.env.REACT_APP_BUILD,
              version: process.env.REACT_APP_VERSION,
            },
          },
          ppTimestamp: new Date(legal_consent?.pp_timestamp).toISOString(),
          ppVersion: legal_consent?.pp_version,
          tosTimestamp: new Date(legal_consent?.tos_timestamp).toISOString(),
          tosVersion: legal_consent?.tos_version,
        });
        return false;
      })
      .then(() => {
        //All consents have been granted, so do not show the prompt anymore
        setRequiredConsents({
          terms_of_service: false,
          privacy_policy: false,
        });
        dispatch(
          db.setNotification(staticTexts.VerifyEmailVerifiedTitle, true)
        );
        getCustomRoute(history);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.log(error);
        const apiError =
          error?.response?.data?.error?.message ?? error?.message;
        // Set state error with the catch error.
        dispatch(
          db.setErrorPage(
            staticTexts.GeneralErrorTitle,
            apiError,
            'error',
            false,
            staticTexts.GeneralErrorClose,
            false,
            false,
            true
          )
        );
      });
  }

  //A user who does not accept the privacy documents cannot proceed
  //with login.
  function logout() {
    const getIsoDate = (date) => {
      return date ? new Date(date).toISOString() : null;
    };
    return new Promise((resolve) => {
      analytics.track('Legal Consent Clickwrap Logout', {
        context: {
          app: {
            name: 'AuthApp',
            namespace: 'components/Privacy/Consent',
            build: process.env.REACT_APP_BUILD,
            version: process.env.REACT_APP_VERSION,
          },
        },
        ppTimestamp: getIsoDate(accountData?.legal_consent?.pp_timestamp),
        ppVersion: accountData?.legal_consent?.pp_version,
        tosTimestamp: getIsoDate(accountData?.legal_consent?.tos_timestamp),
        tosVersion: accountData?.legal_consent?.tos_version,
      });
      setTimeout(function () {
        resolve(true);
      }, 250);
    }).then(() => {
      history.push(routes.SIGN_OUT);
    });
  }

  /**
   * This function checks to see if the user has consented to the
   * latest version of the privacy policy and terms of service.
   *
   * @param {*} user the accountData object fetched from firestore
   * @param {*} privacyDocuments the privacyDocuments collection fetched from firestore
   * @returns boolean
   */
  function consentRequired(user, privacyDocuments) {
    //Copy the requiredConsents variable, so we can modify it locally within this function
    let newConsents = { ...requiredConsents };

    //If the user has never consented to any privacy documents, consider their
    //version to be 0. This is the version published before explicit consent was
    //added.
    const user_terms_of_service_version = user?.legalConsent?.tosVersion || 0;
    const user_privacy_policy_version = user?.legalConsent?.ppVersion || 0;

    //If no required consents have been published, then nobody needs to consent.
    //This is a usecase you might encounter for a few months before any required
    //consents are published.
    if (
      !privacyDocuments?.terms_of_service?.versionNumber &&
      !privacyDocuments?.privacy_policy?.versionNumber
    ) {
      return false;
    }

    //If the user accepted version 1, once version 2 is published,
    //they should see the prompt again
    if (
      user_terms_of_service_version <
      privacyDocuments?.terms_of_service?.versionNumber
    ) {
      newConsents.terms_of_service = true;
    }
    if (
      user_privacy_policy_version <
      privacyDocuments?.privacy_policy?.versionNumber
    ) {
      newConsents.privacy_policy = true;
    }

    //If the user has passed all the above checks, then they have
    //already accepted the most recent privacy documents, and we
    //can send them along to the next login step
    setRequiredConsents(newConsents);
    return newConsents.terms_of_service || newConsents.privacy_policy;
  }

  /**
   * Set the header text to one of the following, depending on which
   * document was updated:
   *  - "Terms of Service Updates"
   *  - "Privacy Policy Updates"
   *  - "Terms of Service & Privacy Policy Updates"
   *
   * @returns string
   */
  function getHeaderText() {
    const headerTexts = {
      terms_of_service: 'Terms of Service Updates',
      privacy_policy: 'Privacy Policy Updates',
      both: 'Terms of Service & Privacy Policy Updates',
    };
    return headerTexts[whichDocument];
  }

  /**
   * Set a variable to either "terms_of_service", "privacy_policy", or "both"
   * depending on which one(s) have been updated. This will be useful for
   * features which behave differently depending which documents have been
   * changed. For instance, a different header text is shown when only one
   * document has been updated vs. if both documents have been updated.
   */
  function getWhichDocument() {
    if (requiredConsents.terms_of_service && requiredConsents.privacy_policy) {
      return constants.BOTH;
    } else if (requiredConsents.terms_of_service) {
      return constants.TERMS_OF_SERVICE;
    } else if (requiredConsents.privacy_policy) {
      return constants.PRIVACY_POLICY;
    }
  }

  /**
   * Navigate the user to the privacy document full text page and highlight the
   * privacy policy tab
   */
  function viewPrivacyPolicyFull(e) {
    e.preventDefault();
    setTab(constants.PRIVACY_POLICY);
    setScreen(constants.SCREEN_VIEW_DOCUMENTS);
  }

  /**
   * Navigate the user to the privacy document full text page and highlight the
   * terms of service tab
   */
  function viewTermsFull(e) {
    e.preventDefault();
    setTab(constants.TERMS_OF_SERVICE);
    setScreen(constants.SCREEN_VIEW_DOCUMENTS);
  }

  /**
   * Navigate the user to the summary of changes page
   */
  function viewSummary(e) {
    e.preventDefault();
    setScreen(constants.SCREEN_CONSENT_PROMPT);
  }
};

export default Privacy;
