import React, { useState } from 'react';
import { authHelper } from 'firebase-config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { onSetAuthUser, setErrorPage } from 'actions/db';
import Provider from './Provider';
import { GOOGLE_PROVIDER } from 'components/ManageProviders/const';
import { MANAGE_PROVIDERS } from 'constants/routes';
import { auth } from 'actions';

const googleProvider = authHelper.google_provider;
const facebookProvider = authHelper.facebook_provider;

const AddSocialProvider = ({ type }) => {
  const [socialEmail, setSocialEmail] = useState(false);

  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const signInWith = async () => {
    const provider =
      type === GOOGLE_PROVIDER ? googleProvider : facebookProvider;
    if (authUser) {
      await auth
        .doLinkWithPopup(provider)
        .then((result) => {
          dispatch(onSetAuthUser(result?.user.toJSON()));
          const { email } = result?.user?.providerData?.filter(
            (provider) => provider.providerId === type
          )[0];
          setSocialEmail(email ?? 'no email provided');
          console.log(`${type} account linked (email: ${email})`);
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error(error);
          dispatch(
            setErrorPage(
              'Error',
              error.message,
              'error',
              false,
              'Close',
              MANAGE_PROVIDERS
            )
          );
        });
    } else {
      console.log('User object is not available yet');
    }
  };

  return socialEmail ? (
    <Provider email={socialEmail} providerId={type} />
  ) : (
    <li>
      <a onClick={signInWith}>{'Connect ' + type}</a>
    </li>
  );
};

export default AddSocialProvider;
