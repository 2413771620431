import { functionsRef, httpsCallable } from '../firebase-config/firebase';
import { api } from '../actions';
import { load } from 'recaptcha-v3';
import { APPS_DOMAIN_OLD, APPS_DOMAIN } from '../constants/const';

// Initiate recaptcha.
export const recaptchaChecker = (action) => {
  const key = process.env.REACT_APP_RECAPTCHA_KEY;
  // If action is login, then check the token and return result.
  return load(key).then((recaptcha) => {
    return recaptcha.execute(action).then((token) => {
      return api.recaptchaChecker(token, action);
    });
  });
};

// Check if user already exists in the DB.
export const checkExistingUser = (email, facebook, skipAuthCheck) => {
  const checkExistingUser = httpsCallable(
    functionsRef,
    'groupAuthHelpers-checkExistingUser'
  );
  return checkExistingUser({ email, facebook, skipAuthCheck });
};

// Hydra action helper.
export const doHydraAction = (data) => {
  const getRedirectUrl = httpsCallable(
    functionsRef,
    'groupBrandConnectionsLight-hydraAuthAppHelper'
  );

  return getRedirectUrl(data);
};

// Check if name is valid
export const isValidName = (text) => {
  const reg = RegExp("^[ a-zA-Z'.-]*$");
  if (!!text && reg.test(String(text))) {
    return true;
  } else {
    return false;
  }
};

/**
 * Helper to get App URL.
 *
 * @return {string}
 */
export const getAppUrl = (name) => {
  let url = `https://${name}`;
  const envs = ['dev', 'stage'];
  if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
    url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev';
  }
  // TODO change to new app domain once switched.
  const currentDomain = window.location.hostname.endsWith(APPS_DOMAIN_OLD)
    ? APPS_DOMAIN_OLD
    : APPS_DOMAIN;
  return url + '.' + currentDomain;
};

/**
 * This function checks to see if the user has consented to the
 * latest version of the privacy policy and terms of service.
 *
 * @param {DocumentData} accountData object fetched from firestore
 * @param {object} privacy the privacy collection fetched from firestore
 * @returns boolean
 */
export const privacyConsentGiven = (accountData, privacy) => {
  if (accountData && privacy) {
    const userTermsOfServiceVersion =
      accountData?.legal_consent?.tos_version || 0;
    const userPrivacyPolicyVersion =
      accountData?.legal_consent?.pp_version || 0;
    const termsOfServiceVersion = privacy?.terms_of_service?.versionNumber;
    const privacyPolicyVersion = privacy?.privacy_policy?.versionNumber;
    if (
      userTermsOfServiceVersion === termsOfServiceVersion &&
      userPrivacyPolicyVersion === privacyPolicyVersion
    ) {
      return true;
    }
    return false;
  }
};
