import React from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import Link from '@mui/material/Link';
import { onSetAuthUser } from 'actions/db';
import { auth } from 'actions';
import { authRef } from 'firebase-config/firebase';

const DisconnectProvider = ({ type, setShowConnect }) => {
  const dispatch = useDispatch();
  const disconnectProvider = async () => {
    if (!authRef?.currentUser) {
      console.error('User is not loaded yet');
      return;
    }

    await auth
      .doUnlink(type)
      .then((user) => {
        dispatch(onSetAuthUser(user.toJSON()));
        setShowConnect(true);
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <Link data-id="disconnect-provider-link" onClick={disconnectProvider}>
      Disconnect
    </Link>
  );
};

export default DisconnectProvider;
