import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import * as routes from 'constants/routes';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContainerBox from 'components/Common/ContainerBox';
import { analytics, api, auth, db, getCustomRoute } from 'actions';
import * as Sentry from '@sentry/browser';
import Preloader from 'components/Preloader';
import staticTexts from 'texts';

const styleOverride = {
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '25px',
    marginBottom: '25px',
  },
};

const PasswordForgetConfirmCodeForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { params } = props;

  const [passwordType, setPasswordType] = useState('password');
  const [fields, setFields] = useState({ password: '' });
  const [error, setError] = useState(null);
  const [fieldsError, setFieldsError] = useState({});
  const [isInvalid, setIsInvalid] = useState(true);
  const [loading, setLoading] = useState(true);

  const authUser = useSelector((state) => state.sessionState.authUser);

  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = { ...defaultFormStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const { setErrorPage } = db;

  let passwordDescription = fieldsError.password
    ? fieldsError.password
    : 'The password must contain minimum eight characters and at least one number.';

  useEffect(() => {
    checkValidForm();
  });

  /**
   * Logout signed in users.
   * Current URL should be visited in anonymous mode only.
   */
  useEffect(() => {
    if (authUser) {
      if (params?.mode !== 'verifyEmail') {
        dispatch(
          db.setNotification(
            staticTexts.NewPasswordSignOutError ??
              "You've been signed out of your current active account. Please reopen the link to continue.",
            true
          )
        );
        history.push(routes.SIGN_OUT);
      }
    }
    // eslint-disable-next-line
  }, [])


  /**
   * Show "Your Email has been verified." notification.
   */
  useEffect(() => {
    if (params.mode === 'resetPassword' && params?.login) {
      // Show message.
      dispatch(db.setNotification(staticTexts.VerifyEmailSuccessTitle, true));
    }
  }, []);

  useEffect(() => {
    // Reset Password form.
    if (params.mode === 'resetPassword' && params.oobCode) {
      auth
        .verifyPasswordResetCode(params.oobCode)
        .then(() => {
          setLoading(false);
        })
        .catch(async (error) => {
          console.error('verify-password-reset-code', error);
          if (error.code === 'auth/expired-action-code') {
            // Get user's email address.
            if (params.token) {
              api
                .sendPasswordResetEmail(params.token)
                .then(() => {
                  setLoading(false);
                  dispatch(
                    db.setErrorPage(
                      staticTexts.PasswordForgetExpiredTitle,
                      staticTexts.PasswordForgetResendSuccessDescription,
                      'info',
                      staticTexts.PasswordForgetSuccessMoreDescription
                    )
                  );
                })
                .catch((error) => {
                  console.log(`error message: ${error.message}`, error);
                  // Redirect to resend link page.
                  history.push({
                    pathname: routes.PASSWORD_FORGET,
                    search: '?expired=true',
                  });
                });
            } else {
              // Redirect to resend link page.
              history.push({
                pathname: routes.PASSWORD_FORGET,
                search: '?expired=true',
              });
            }
          } else {
            Sentry.captureException(error);
            setLoading(false);
            dispatch(
              setErrorPage(
                staticTexts.NewPasswordGeneralErrorTitle,
                error.message,
                'error',
                false,
                staticTexts.NewPasswordGeneralErrorRequestButtonSignIn,
                routes.SIGN_IN
              )
            );
          }
        });
      analytics.page('Password Forget Confirm');
    }
    // eslint-disable-next-line
  }, [])

  const initialState = () => {
    setFields({ password: '' });
    setLoading(false);
    setPasswordType('password');
    setFieldsError({});
    setFields({});
    setError(null);
    setIsInvalid(true);
  };

  const onSubmitPass = (event) => {
    setLoading(true);
    event.preventDefault();
    auth
      .confirmPasswordReset(params.oobCode, fields.password)
      .then(() => {
        if (params.login) {
          // Sign in user with customToken.
          auth
            .doSignInWithCustomToken(params.login)
            .then(() => {
              getCustomRoute(history);
            })
            .catch((error) => {
              Sentry.captureException(error);
              console.error(error);
            });
        } else {
          // Show message.
          dispatch(
            db.setNotification(
              staticTexts.NewPasswordGeneralSussessDescription,
              true
            )
          );
          history.push(routes.SIGN_IN);
        }
      })
      .then(() => {
        return initialState();
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error('confirm-password-reset', error);

        setError(error);
        setLoading(false);
      });
  };

  const doValidatePassword = (password) => {
    // Validate password.
    const regLenght = /^.{8,}$/;
    const regNumber = /\d+/;
    const regLetter = /[A-Za-z]/;

    if (
      regLenght.test(password) &&
      regNumber.test(password) &&
      regLetter.test(password)
    ) {
      setFieldsError({
        ...fieldsError,
        password: false,
      });
    } else {
      let errorText = staticTexts.PasswordVerificationTitle;
      if (!regLenght.test(password)) {
        errorText += staticTexts.PasswordVerificationEightCharacters;
      }
      if (!regNumber.test(password)) {
        errorText += staticTexts.PasswordVerificationOneNumber;
      }
      if (!regLetter.test(password)) {
        errorText += staticTexts.PasswordVerificationOneLetter;
      }

      setFieldsError({
        ...fieldsError,
        password: errorText,
      });
    }
  };

  const onChangeInput = (event) => {
    let fieldName = event.target.id;
    let fieldValue = event.target.value;

    // Set field.
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const inputValidation = (e, name, notEmpty, customValidator) => {
    let fieldName = name ? name : e.target.id;
    let fieldNameIsRequired = staticTexts.FieldIsRequired.replace(
      '@field',
      fieldName
    );

    if (customValidator === 'password') {
      if (notEmpty && e.target.value === '') {
        setFieldsError({
          ...fieldsError,
          [e.target.id]: fieldNameIsRequired,
        });
      } else {
        doValidatePassword(e.target.value);
      }
    } else if (notEmpty && e.target.value === '') {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: fieldNameIsRequired,
      });
    } else {
      setFieldsError({
        ...fieldsError,
        [e.target.id]: false,
      });
    }
  };

  const checkValidForm = () => {
    if (
      (typeof fieldsError.password === 'undefined' &&
        typeof fields.password !== 'undefined' &&
        fields.password !== '') ||
      fieldsError.password === false
    ) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const togglePasswordType = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  if (loading) {
    return <Preloader title={staticTexts.NewPasswordLoading} />;
  } else {
    return (
      <ContainerBox>
        <IconButton
          aria-label="back"
          className={classes.back}
          onClick={() => history.push(routes.SIGN_IN_EMAIL)}
        >
          <ArrowBackIosIcon fontSize="medium" />
        </IconButton>
        <Typography variant="h1" className={classes.title} component="h1">
          {params?.login ? 'Create password' : staticTexts.NewPasswordTitle}
        </Typography>
        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={onSubmitPass}
        >
          <FormControl
            error={!!fieldsError.password || !!error}
            variant="filled"
            fullWidth
          >
            <InputLabel htmlFor="password">
              {params?.login ? 'Create password' : staticTexts.NewPasswordLabel}
            </InputLabel>
            <FilledInput
              id="password"
              className={classes.field}
              type={passwordType}
              value={fields.password}
              onChange={(e) => {
                onChangeInput(e);
                inputValidation(e, staticTexts.Password, true, 'password');
              }}
              onBlur={(e) => {
                inputValidation(e, staticTexts.Password, true, 'password');
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordType}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {passwordType === 'password' ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              data-id="password-forget-new-password"
            />
            <FormHelperText className={classes.marginHelper}>
              {error ? error.message : passwordDescription}
            </FormHelperText>
          </FormControl>
          <Button
            className={classes.button}
            variant={isInvalid ? 'outlined' : 'contained'}
            disabled={isInvalid}
            color="primary"
            type="submit"
            disableElevation
            fullWidth
            data-id="new-password-button"
            size="large"
          >
            {params?.login ? 'Submit' : staticTexts.NewPasswordButton}
          </Button>

          {!params?.login && (
            <Typography variant="body2" component="div">
              <Link
                href={'#'}
                className={classes.link}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(routes.SIGN_IN);
                }}
                data-id="new-password-signin-link"
              >
                {staticTexts.NewPasswordSignInLink}
              </Link>
            </Typography>
          )}
          {params?.login && (
            <Typography variant="subtitle2" component="div">
              By signing up you agree to our &nbsp;
              <a href="https://www.wesalute.com/terms-service">
                Terms of Service
              </a>
            </Typography>
          )}
        </form>
      </ContainerBox>
    );
  }
};

export default PasswordForgetConfirmCodeForm;
