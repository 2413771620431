import store from '../store';
import { VASegmentTracking } from '@wesalute/lib-segmentio-js';
/**
 * Helper to get memberId.
 *
 * @return {string} memberId
 */
const getMemberId = () => {
  // Get redux store state.
  const state = store.getState();
  return state?.accountState?.account?.member_id ?? null;
};

/**
 * Common init method.
 *
 * @returns {null}
 */
const init = () => {
  return new VASegmentTracking('Auth App', getMemberId());
};

/**
 * Segment load helper.
 *
 * @returns {*}
 */
export const load = (segmentKey) => {
  return VASegmentTracking.load(segmentKey);
};

/**
 * Helper wrapper for common Segment page() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.page}
 */
export const page = (name, properties) => {
  const vaAnalytics = init();
  return vaAnalytics.page(name, properties);
};

/**
 * Helper wrapper for common Segment track() method.
 *
 * @param string name
 * @param object properties
 * @returns {VASegmentTracking.track}
 */
export const track = (name, properties) => {
  const vaAnalytics = init();
  return vaAnalytics.track(name, properties);
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const identify = () => {
  const vaAnalytics = init();
  return vaAnalytics.identify();
};

/**
 * Common identify processing wrapper.
 *
 * @returns {*}
 */
export const reset = () => {
  return VASegmentTracking.reset();
};
