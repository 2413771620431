import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { analytics, api, auth, db, getCustomRoute } from 'actions';
import * as routes from 'constants/routes';
import Preloader from 'components/Preloader';
import staticTexts from 'texts';

const EmailConfirmForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const params = useSelector((state) => state.sessionDBState.data.urlQuery);
  const [loading, setLoading] = useState(true);

  /**
   * Login user if needed.
   */
  useEffect(() => {
    if (params.signin && authUser === false) {
      // Sign in user with customToken.
      auth
        .doSignInWithCustomToken(params.signin)
        .then(({ user }) => {
          return user?.reload();
        })
        .catch((error) => {
          // @see CX-3236, we do ignore auth/user-token-expired error after email change.
          if (error?.code !== 'auth/user-token-expired') {
            console.error('Error login user', error.message);
            Sentry.captureException(error);
            history.push(routes.SIGN_OUT);
          }
        });
    }
  }, [authUser]);

  useEffect(() => {
    if (params.token && authUser) {
      api
        .confirmEmail(params.token)
        .then(() => {
          dispatch(db.fetchAccountInfo());
          analytics.track('Email Verification Verified', {
            email: authUser?.email,
          });
          return auth.reloadUser();
        })
        .catch((error) => {
          // @see CX-3236, we do ignore auth/user-token-expired error after email change.
          if (error?.code !== 'auth/user-token-expired') {
            console.log(`error message: ${error.message}`, error);
            Sentry.captureException(error);
            history.push(routes.SIGN_OUT);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      console.log('Empty token parameter');
    }
  }, [authUser]);

  useEffect(() => {
    if (loading === false && accountData?.verification?.email) {
      getCustomRoute(history);
    }
  }, [loading, accountData]);

  return <Preloader title={staticTexts.NewPasswordLoading} />;
};

export default EmailConfirmForm;
