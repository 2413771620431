import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';

import {
  APPS_DOMAIN,
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_NAME_OLD,
} from 'constants/const';
import Preloader from 'components/Preloader';
import * as routes from 'constants/routes';
import staticTexts from 'texts';

import { analytics, api, db, getCustomRoute, helpers } from 'actions';
import { AxiosError } from 'axios';
import { authRef } from 'firebase-config/firebase';

/**
 * This page confirms that the user is logged in, and writes their session
 * cookie. It should probably be called "doLogin" or something similar.
 */
const Verify = (props) => {
  const { hydraChallenge } = props;
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountData = useSelector((state) => state.accountState.account);
  const hash = useSelector((state) => state.sessionDBState.data.hash);
  const dispatch = useDispatch();
  const [initiate, setInitiate] = useState(false);

  const { setErrorPage } = db;

  useEffect(() => {
    analytics.page('Verify');
    // eslint-disable-next-line
  }, [])

  /**
   * Move Hydra redirect upper for better performance.
   * @see CX-2789.
   */
  useEffect(() => {
    if (hydraChallenge && accountData?.member_id) {
      helpers
        .doHydraAction({
          search: hydraChallenge,
          memberId: accountData.member_id,
          action: 'redirect',
        })
        .then((redirect) => {
          if (redirect?.data) {
            window.location.href = redirect.data;
          } else {
            throw Error('Error getting redirect');
          }
        })
        .catch((error) => {
          Sentry.captureException(error);
          console.error('hydra', error);

          dispatch(
            setErrorPage(
              staticTexts.ErrorDefaultTitle,
              error.message,
              'error',
              false,
              staticTexts.ErrorDefaultButton,
              routes.SIGN_IN
            )
          );
        });
    }
  }, [accountData?.member_id]);

  useEffect(() => {
    if (authUser === false) {
      getCustomRoute(history);
    } else {
      const finishLogin = () => {
        const { returnType, returnUrl, providerToken, sessionDbId } = props;
        setInitiate(true);

        if (returnType === 'widget') {
          let returnUrlArgs = returnUrl ? returnUrl.split('?') : [];
          let compiledReturnUrl =
            returnUrl +
            '?providerToken=' +
            providerToken +
            '&sessionId=' +
            sessionDbId +
            '&version=latest&xcomponent=1';

          if (
            returnUrlArgs &&
            returnUrlArgs.length > 1 &&
            returnUrlArgs[1] &&
            returnUrlArgs[1] !== ''
          ) {
            // Parameters exists, so join the arguments.
            compiledReturnUrl =
              returnUrl +
              '&providerToken=' +
              providerToken +
              '&sessionId=' +
              sessionDbId +
              '&version=latest&xcomponent=1';
          }

          // If verify integration of milvetId is set, redirect to verify app.
          if (!isCheckoutUrl(returnUrl) && !accountData.verified) {
            compiledReturnUrl =
              helpers.getAppUrl('verify') +
              '?sessionId=' +
              sessionDbId +
              '&return_to=' +
              encodeURIComponent(compiledReturnUrl) +
              '&providerToken=' +
              providerToken;
          }

          return setPlatformToken(compiledReturnUrl);
        } else if (returnType === 'verify') {
          const redirectUrl = hash ? returnUrl + hash : returnUrl;
          return setPlatformToken(redirectUrl);
        } else {
          let returnUrlData;

          returnUrlData = returnUrl
            ? returnUrl
            : process.env.REACT_APP_PLATFORM_URL;
          // Override default URL if returnUrl is equal to platform URL.
          if (returnUrl === process.env.REACT_APP_PLATFORM_URL) {
            returnUrlData = process.env.REACT_APP_PLATFORM_URL + '/marketplace';
          }

          setPlatformToken(returnUrlData);
        }
      };

      const setPlatformToken = async (returnUrlData) => {
        return api
          .setToken(authRef?.currentUser?.refreshToken)
          .then(({ data }) => {
            // Save token in db.
            if (data?.data?.token) {
              // Set cookies for local development new domain.
              const options = {
                expires: 1,
                path: '/',
                domain: `.${APPS_DOMAIN}`,
              };
              // Set loginTokenSession cookie.
              Cookies.set(AUTH_COOKIE_NAME, data?.data?.token, options);
              // Set customToken legacy cookie.
              Cookies.set(AUTH_COOKIE_NAME_OLD, data?.data?.token, options);
            }
            return false;
          })
          .then(() => {
            window.location.href = returnUrlData;
          })
          .catch((error) => {
            Sentry.captureException(error);
            console.error('verify', error);
            const badRequest = error?.code === AxiosError.ERR_BAD_REQUEST;
            dispatch(
              setErrorPage(
                staticTexts.ErrorDefaultTitle,
                badRequest
                  ? staticTexts.VerifyBadRequestMessage
                  : error.message,
                'error',
                false,
                badRequest
                  ? staticTexts.ErrorSuspendedAccountButton
                  : staticTexts.ErrorDefaultButton,
                routes.SIGN_OUT
              )
            );
          });
      };

      /**
       * Help to detect Checkout app from returnUrl parameter.
       *
       * @param string
       * @returns {boolean}
       */
      const isCheckoutUrl = (string) => {
        let result = false;
        try {
          let url = new URL(string);
          const matcher = new RegExp('https:\\/\\/checkout.*' + APPS_DOMAIN);
          if (matcher.test(url.href)) {
            result = true;
          }
        } catch (e) {}
        return result;
      };

      if (!initiate && !hydraChallenge) {
        finishLogin();
      }
    }
    // eslint-disable-next-line
  }, [initiate, accountData, authUser, staticTexts])

  return (
    <div>
      <Preloader title={staticTexts.VerificationPreloaderTitle} />
    </div>
  );
};

export default Verify;
