import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Preloader from 'components/Preloader';
import * as routes from 'constants/routes';
import staticTexts from 'texts';

import { analytics, api, db } from 'actions';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import {
  VERIFICATION_EMAIL_EDIT_VALUE,
  VERIFICATION_EMAIL_SENT,
  VERIFICATION_EMAIL_SHOW_POPUP,
} from 'constants/const';
import { useWesaluteRecaptcha } from '../../../hooks';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import InputLabel from '@mui/material/InputLabel';
import { authRef } from 'firebase-config/firebase';

const styleOverride = {
  description: {
    whiteSpace: 'pre-line',
    marginBottom: '48px',
  },
};

const EmailVerificationEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [recaptcha] = useWesaluteRecaptcha('email_verification_edit');
  const authUser = useSelector((state) => state.sessionState.authUser);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const emailSent = window.sessionStorage.getItem(VERIFICATION_EMAIL_SENT);
  const [loading, setLoading] = useState(true);
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(emailSent);

  const [email, setEmail] = useState(
    window.sessionStorage.getItem(VERIFICATION_EMAIL_EDIT_VALUE)
  );
  const [emailError, setEmailError] = useState('');
  const [emailValidationInProgress, setEmailValidationInProgress] =
    useState(false);
  const [activeButton, setActiveButton] = useState(false);

  const { setErrorPage } = db;

  useEffect(() => {
    if (authUser) {
      setLoading(false);
    } else {
      history.push(routes.SIGN_IN);
    }
  }, [authUser]);

  const doSendConfirmationEmail = async () => {
    const token = await authRef?.currentUser.getIdToken();
    api
      .sendEmailVerification(email, token)
      .then(() => {
        analytics.track('Email Verification Started', {
          email: authUser.email,
        });
        setConfirmationEmailSent(true);
        window.sessionStorage.setItem(VERIFICATION_EMAIL_SENT, 'true');
        window.sessionStorage.setItem(VERIFICATION_EMAIL_EDIT_VALUE, email);
        window.sessionStorage.setItem(VERIFICATION_EMAIL_SHOW_POPUP, 'true');
        history.push(routes.SIGN_OUT);
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(
          setErrorPage(
            staticTexts.GeneralErrorTitle,
            error.message,
            'error',
            false,
            staticTexts.HHMembersWidgetAlertBtn,
            routes.SIGN_IN,
            false,
            false,
            true
          )
        );
      });
  };

  const doValidateEmail = (email) => {
    setEmailValidationInProgress(true);

    // Validate email.
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regMilEmail = RegExp(
      '^[A-Za-z0-9._%+-]+@([a-z0-9][-a-z0-9\\.]*[a-z0-9].mil)$'
    );

    // Check if it is a valid email format.
    if (!reg.test(String(email).toLowerCase())) {
      setEmailError(staticTexts.EmailVerificationNotValid);
      setEmailValidationInProgress(false);
    } else if (regMilEmail.test(String(email).toLowerCase())) {
      // Check if it is a .mil email.
      setEmailError(staticTexts.EmailVerificationActiveDutyEmail);
      setEmailValidationInProgress(false);
    } else {
      // Check if it is an active domain email.
      api
        .verifyEmailDomain(email)
        .then(() => {
          setEmailError('');
          setEmail(email);
          setActiveButton(true);
        })
        .catch((error) => {
          setActiveButton(false);
          Sentry.captureException(error);
          console.error(error);
          const apiError =
            error?.response?.data?.error?.message ?? error?.message;
          setEmailError(apiError);
        })
        .finally(() => {
          setEmailValidationInProgress(false);
        });

      return true;
    }
  };

  if (!recaptcha) {
    return <Preloader title={staticTexts.NewPasswordLoading} />;
  } else if (loading) {
    return <Preloader />;
  } else {
    return (
      <React.Fragment>
        {confirmationEmailSent && authUser?.email && (
          <Box
            p={{ xs: 2, md: 10 }}
            boxShadow={{ xs: 0, sm: 0, md: 6 }}
            className={classes.box}
          >
            <IconButton
              aria-label="back"
              className={classes.back}
              onClick={() => history.push(routes.EMAIL_VERIFICATION)}
            >
              <ArrowBackIosIcon fontSize="medium" />
            </IconButton>
            <Typography variant="h1" className={classes.title} component="h1">
              {staticTexts.EmailVerificationEditTitle ?? 'Change email'}
            </Typography>

            <Typography
              variant="body2"
              className={classes.description}
              component="h2"
            >
              {staticTexts.EmailVerificationEditDescription ??
                'Please change your email address only when you sure that previous value is wrong. After submitting new email logout required.'}
            </Typography>

            <FormControl error={!!emailError} variant="filled" fullWidth>
              <InputLabel htmlFor="firstname">
                {staticTexts.EmailVerificationPlaceholder}
              </InputLabel>
              <FilledInput
                id="email"
                className={classes.field}
                disabled={emailValidationInProgress}
                value={email ? email : ''}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                onBlur={(e) => doValidateEmail(e.target.value)}
                endAdornment={
                  emailValidationInProgress ? (
                    <InputAdornment position="end">
                      <CircularProgress />
                    </InputAdornment>
                  ) : null
                }
                data-id="email"
              />
              <FormHelperText className={classes.marginHelper}>
                {emailError}
              </FormHelperText>
            </FormControl>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              data-id="edit-email"
              disabled={!activeButton}
              onClick={() => doSendConfirmationEmail()}
            >
              {staticTexts.EmailVerificationEditButton ??
                'Save Email and Send New Link'}
            </Button>
          </Box>
        )}
      </React.Fragment>
    );
  }
};

export default EmailVerificationEdit;
