import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';

import Preloader from 'components/Preloader';
import * as routes from 'constants/routes';
import {
  VERIFICATION_EMAIL_SENT,
  VERIFICATION_EMAIL_EDIT_VALUE,
} from 'constants/const';
import staticTexts from 'texts';

import { analytics, api, db, getCustomRoute } from 'actions';
import { useGetAccountData } from 'hooks';
import EmailDetails from './EmailDetails';
import EmailEditBlock from './EmailEditBlock';
import { authRef } from 'firebase-config/firebase';

const styleOverride = {
  button: {
    borderRadius: '0',
    paddingTop: '14px',
    paddingBottom: '14px',
    marginTop: '10px',
    marginBottom: '24px',
  },
};
const initialTimeLeft = 59;

const EmailVerification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector((state) => state.sessionState.authUser);
  const accountDataLoaded = useGetAccountData('verification.email');
  const accountData = useSelector((state) => state.accountState.account);
  const return_to = useSelector(
    (state) => state.sessionDBState.data.urlQuery?.return_to
  );
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const defaultFormStyles = useSelector(
    (state) => state.sessionDBState.data.defaultFormStyles
  );
  const customStyles = {
    ...defaultDashboardStyles,
    ...defaultFormStyles,
    ...styleOverride,
  };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  const emailSent = window.sessionStorage.getItem(VERIFICATION_EMAIL_SENT);
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState(null);
  const [counter, setCounter] = useState(initialTimeLeft);
  const [counterText, setCounterText] = useState(initialTimeLeft);
  const [confirmationEmailSent, setConfirmationEmailSent] = useState(emailSent);
  const editEmail = window.sessionStorage.getItem(
    VERIFICATION_EMAIL_EDIT_VALUE
  );

  const { setErrorPage } = db;

  useEffect(() => {
    if (accountData?.member_id) {
      if (!confirmationEmailSent) {
        doSendConfirmationEmail();
      } else {
        setLoading(false);
        startTimer();
      }

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line
  }, [accountData?.member_id])

  useEffect(() => {
    if (authUser === false) {
      getCustomRoute(history);
    }
  }, [authUser, accountData]);

  useEffect(() => {
    if (!accountData?.verification?.email) {
      if (!confirmationEmailSent && !authUser?.emailVerified) {
        setLoading(false);
      }
    } else if (accountDataLoaded) {
      dispatch(db.setNotification(staticTexts.VerifyEmailSuccessTitle, true));
      getCustomRoute(history);
    }
  }, [confirmationEmailSent, accountData]);

  const doSendConfirmationEmail = async () => {
    const token = await authRef?.currentUser?.getIdToken();
    api
      .sendEmailVerification(authUser.email, token, return_to)
      .then(() => {
        analytics.track('Email Verification Started', {
          email: authUser.email,
        });
        setConfirmationEmailSent(true);
        window.sessionStorage.setItem(VERIFICATION_EMAIL_SENT, 'true');
        resetTimer();
        setLoading(false);
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(
          setErrorPage(
            staticTexts.GeneralErrorTitle,
            error.message,
            'error',
            false,
            staticTexts.HHMembersWidgetAlertBtn,
            routes.SIGN_IN,
            false,
            false,
            true
          )
        );
      });
  };

  const startTimer = () => {
    let timeLeft = initialTimeLeft;
    setCounterText(
      staticTexts.VerifyEmailBtnResendCount.replace('@counter', timeLeft)
    );

    setTimer(
      setInterval(() => {
        if (timeLeft > 0) {
          timeLeft--;
          setCounter(timeLeft);
          setCounterText(
            staticTexts.VerifyEmailBtnResendCount.replace('@counter', timeLeft)
          );
        } else {
          stopTimer();
        }
      }, 1000)
    );
  };

  const stopTimer = () => {
    clearInterval(timer);
  };

  const resetTimer = () => {
    setCounter(initialTimeLeft);
    clearInterval(timer);
    startTimer();
  };

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <React.Fragment>
        {confirmationEmailSent && authUser?.email && (
          <Box
            p={{ xs: 2, md: 10 }}
            boxShadow={{ xs: 0, sm: 0, md: 6 }}
            className={classes.box}
          >
            <Typography variant="h1" className={classes.title} component="h1">
              {staticTexts.VerifyEmailSentTitle}
            </Typography>

            <Typography
              variant="body2"
              className={classes.description}
              component="h2"
            >
              {staticTexts.VerifyEmailSentDesc}{' '}
              <b>{editEmail ? editEmail : authUser.email}</b>
            </Typography>
            <Button
              className={classes.button}
              onClick={doSendConfirmationEmail}
              variant={counter !== 0 ? 'outlined' : 'contained'}
              color={counter !== 0 ? 'secondary' : 'primary'}
              disableElevation
              fullWidth
              disabled={counter !== 0}
              data-id="email-verification-button"
              size="large"
            >
              <EmailIcon className={classes.icon} />
              {counter !== 0 && <React.Fragment>{counterText}</React.Fragment>}
              {counter === 0 && (
                <React.Fragment>
                  {staticTexts.VerifyEmailBtnResend}
                </React.Fragment>
              )}
            </Button>
            <EmailDetails />

            <EmailEditBlock />
          </Box>
        )}
        {!confirmationEmailSent && (
          <Preloader
            title={
              staticTexts.VerifyEmailSentLoading ??
              'Sending verification email...'
            }
          />
        )}
      </React.Fragment>
    );
  }
};

export default EmailVerification;
