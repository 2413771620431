import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider } from '@mui/material/styles';

import { styles } from 'styles';
import * as routes from 'constants/routes';
import {
  app,
  authRef,
  authHelper,
  deleteApp,
  persistenceLocal,
} from 'firebase-config/firebase';
import { analytics, db } from 'actions';

import Preloader from 'components/Preloader';
import SignUpForm from 'components/Sign/SignUpForm';
import SignIn from 'components/Sign/SignIn';
import EmailVerification from 'components/Verification/EmailVerification';
import EmailVerificationEdit from 'components/Verification/EmailVerificationEdit';
import Verify from 'components/Verification/Verify';
import PasswordForget from 'components/Sign/PasswordForget';
import PasswordForgetConfirm from 'components/Sign/PasswordForgetConfirm';
import SignOut from 'components/Sign/SignOut';
import ContactUs from 'components/StaticPages/ContactUs';
import DialogDefault from 'components/StaticPages/DialogDefault';
import Header from 'components/Common/Header';
import SignInForm from 'components/Sign/SignIn/SignInForm';
import UserDashboard from 'components/Sign/UserDashboard';
import SignUpDashboard from 'components/Sign/SignUpDashboard';
import SignUpWithRedirect from 'components/Sign/SignUpWithRedirect';
import Consent from 'components/Hydra/Consent';
import Privacy from 'components/Privacy';
import VerifyAppVerification from 'components/Verification/VerifyAppVerification';
import { ManageProviders } from 'components/ManageProviders';
import {
  FACEBOOK_PROVIDER,
  GOOGLE_PROVIDER,
} from 'components/ManageProviders/const';
import {
  AddSocialDeepLink,
  DisconnectSocialDeepLink,
} from 'components/ManageProviders/DeepLink';
import Page404 from 'components/StaticPages/Page404';
import Page401 from 'components/StaticPages/Page401';
import EmailConfirm from 'components/Sign/EmailConfirm';
import Logout from 'components/Common/Logout/Logout';

const theme = createTheme(styles.theme);
const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;

import { APPS_DOMAIN, APPS_DOMAIN_OLD } from 'constants/const';
import { getAllIdentificationStatuses } from 'actions/identification';
import Notification from 'components/Common/Notification';

import staticTexts from 'texts';

const App = ({ sessionTimeout = '180000' }) => {
  const location = useLocation();

  const dispatch = useDispatch();
  const appDeleted = useRef(false);
  const identifyTracked = useRef(false);

  const [urlQueryData, setUrlQueryData] = useState({});
  const [mainErrorPage, setMainErrorPage] = useState(false);
  const [returnType, setReturnType] = useState(null);
  const [source, setSource] = useState('');
  const [allowTests, setAllowTests] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const logo = '/assets/images/we-salute-logo.svg';
  const [pageWidth, setPageWidth] = useState('md');
  const accountDataStatus = useSelector((state) => state.accountState.status);
  const accountData = useSelector((state) => state.accountState.account);
  const brandData = useSelector((state) => state.brandState.data);
  const brandDataStatus = useSelector((state) => state.brandState.status);
  const errorPageData = useSelector((state) => state.errorState.active);
  const dialogData = useSelector((state) => state.dialogState.active);

  const {
    updateSession,
    fetchAccountInfo,
    fetchBrandInfo,
    fetchPrivacyInfo,
    setErrorPage,
    clearErrorPage,
    onSetAuthUser,
  } = db;

  useEffect(() => {
    // State the version of app.
    if (process.env.REACT_APP_ENV === 'dev') {
      console.log(process.env.REACT_APP_VERSION);
    }

    // Set default styles in local session.
    dispatch(
      updateSession({
        defaultFormStyles: styles.defaultFormStyles,
        defaultDashboardStyles: styles.defaultDashboardStyles,
        defaultAlertStyles: styles.defaultAlertStyles,
        defaultDialogStyles: styles.defaultDialogStyles,
        referrer: document.referrer ? document.referrer : null,
      })
    );

    // Segment initialization.
    if (
      window.analytics &&
      (typeof window.analytics.initialized === 'undefined' ||
        !window.analytics.initialized)
    ) {
      const segmentKey = process.env.REACT_APP_SEGMENTKEY;
      analytics.load(segmentKey);
    }

    // Process required query params.
    let urlQuery = {};
    const urlQueryParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlQueryParams.entries()) {
      urlQuery[key] = value;
    }

    // If we have providerToken, fetch brand info.
    if (urlQuery.providerToken !== 'vaplatformdefault') {
      dispatch(fetchBrandInfo(urlQuery.providerToken));
    }

    if (
      process.env.REACT_APP_ENV !== 'local-dev' &&
      process.env.REACT_APP_ENV !== 'emulator' &&
      typeof urlQuery.return_to !== 'undefined'
    ) {
      try {
        const redirectUrl = new URL(urlQuery.return_to);
        // Remove url redirect which is not a subdomain of VA.
        if (
          redirectUrl.host &&
          !redirectUrl.host.endsWith(`.${APPS_DOMAIN_OLD}`) &&
          !redirectUrl.host.endsWith(`.${APPS_DOMAIN}`) &&
          process.env.REACT_APP_ENV !== 'dev'
        ) {
          delete urlQuery.return_to;
        }
      } catch (e) {
        // Unset return_to if non valid url supplied.
        delete urlQuery.return_to;
      }
    }

    // Set return type and source.
    setReturnType(
      typeof urlQuery.providerToken !== 'undefined' &&
        urlQuery.providerToken === 'vaplatformdefault'
        ? 'verify'
        : typeof urlQuery.login_challenge !== 'undefined'
          ? 'hydra'
          : typeof urlQuery.sessionId !== 'undefined'
            ? 'widget'
            : typeof urlQuery.return_to !== 'undefined'
              ? 'platform'
              : null
    );

    setSource(
      typeof urlQuery.providerToken !== 'undefined' &&
        urlQuery.providerToken === 'vaplatformdefault'
        ? 'VerifyApp'
        : typeof urlQuery.sessionId !== 'undefined'
          ? 'MilVetID'
          : typeof urlQuery.brand_id !== 'undefined'
            ? 'VAZendesk'
            : 'VAPlatform'
    );

    // Set Allow tests flag.
    if (
      process.env.REACT_APP_ENV === 'dev' ||
      process.env.REACT_APP_ENV === 'stage'
    ) {
      setAllowTests(Boolean(urlQuery.allowTests));
    }

    setUrlQueryData(urlQuery);

    if (urlQuery) {
      dispatch(
        updateSession({
          urlQuery: urlQuery,
          hash: window?.location?.hash,
        })
      );
    }

    // Get privacy info.
    dispatch(fetchPrivacyInfo());

    // If flag is set, sign out user.
    authHelper.onAuthStateChanged(authRef, (authUserRef) => {
      if (authUserRef) {
        const auth = authUserRef.toJSON();
        dispatch(onSetAuthUser(auth));
        dispatch(fetchAccountInfo());
      } else {
        dispatch(onSetAuthUser(false));
      }
    });
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const unloadCallback = () => {
      if (!appDeleted.current) {
        appDeleted.current = true;
        return deleteApp(app);
      }
    };
    window.addEventListener('beforeunload', unloadCallback);
    return async () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    // Hide header on specific pages.
    if (location?.pathname === routes.HYDRA_CONSENT) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }

    // Dynamic content width based on route.
    if (location?.pathname === routes.PRIVACY) {
      setPageWidth('lg');
    }
    if (location?.pathname === routes.HYDRA_CONSENT) {
      setPageWidth('mid');
    } else if (location?.pathname === routes.VERIFY_APP_VERIFICATION) {
      setPageWidth('lg');
    } else {
      setPageWidth('md');
    }
  }, [location]);

  const trySetPersistence = async () => {
    try {
      await authRef.setPersistence(persistenceLocal);
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  useEffect(() => {
    trySetPersistence();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // Set return type to local session.
    dispatch(
      updateSession({
        returnType: returnType,
      })
    );
    // eslint-disable-next-line
  }, [returnType])

  // Check Identification statuses and show or hide the revoked alert.
  useEffect(() => {
    if (accountDataStatus === 'loaded') {
      const { militaryStatus, identityStatus } =
        getAllIdentificationStatuses(accountData);

      if (militaryStatus === 'revoked' || identityStatus === 'revoked') {
        setMainErrorPage(true);
        dispatch(
          setErrorPage(
            staticTexts.ErrorSuspendedAccount,
            staticTexts.ErrorSuspendedAccountDescpiption,
            'error',
            false,
            staticTexts.ErrorSuspendedAccountButton,
            routes.SIGN_OUT
          )
        );
      } else {
        dispatch(clearErrorPage());
        setMainErrorPage(false);
      }
    }
  }, [accountDataStatus, accountData]);

  useEffect(() => {
    if (isIE) {
      dispatch(
        setErrorPage(
          false,
          false,
          'error',
          'To enjoy our website and access the best military discounts, use @chrome @safari, or @mozilla.',
          false,
          false,
          false,
          false,
          false,
          'Wait! Did you know that your browser is likely out of date?'
        )
      );
    }
    // eslint-disable-next-line
  }, [isIE, errorPageData])

  useEffect(() => {
    if (accountData?.member_id) {
      Sentry.setUser({ id: accountData?.member_id });
      // Track identify only once.
      if (!identifyTracked.current) {
        analytics.identify();
        identifyTracked.current = true;
      }
    }
  }, [accountData]);

  // Inject Transcend script.
  useEffect(() => {
    if (window.location.hash.includes('skiptranscend')) {
      console.log('Skipped Transcend widget');
      return;
    }

    const exists = document.getElementById('transcend-script');
    if (!exists) {
      const script = document.createElement('script');
      script.id = 'transcend-script';
      const isProd = process.env.REACT_APP_ENV === 'prod';
      script.src = `https://privacy-modules.wesalute.com/cm${!isProd ? '-test' : ''}/408a9d8d-5e3f-4740-bd59-b3ba14eb8230/airgap.js`;
      const scriptNodes = document.getElementsByTagName('script')[0];
      const scriptNode = scriptNodes.length ? scriptNodes[0] : null;
      if (scriptNode) {
        scriptNode.parentNode.insertBefore(script, scriptNode);
      } else {
        document.head.insertBefore(script, null);
      }

      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  if (returnType === 'widget' && brandDataStatus === 'loading') {
    return (
      // If the info is not loaded, show preloader.
      <>
        <CssBaseline />
        <Preloader title="Loading app. Please wait." />
      </>
    );
  } else {
    // Set all the routes after the info is loaded.
    // Note: we are passing just the info that is needed, and filter the static data manually.
    return (
      <>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {showHeader && (
              <Header logo={logo} brandData={brandData ? brandData : null} />
            )}
            <Notification />
            <Container
              fixed
              maxWidth={pageWidth}
              className={'app'}
              disableGutters
            >
              {errorPageData && <ContactUs />}
              {dialogData && <DialogDefault />}
              <Switch>
                <Route
                  exact
                  path={routes.SIGN_UP}
                  render={() => <SignUpDashboard />}
                />
                <Route
                  exact
                  path={routes.SIGNUP_WITH_REDIRECT}
                  render={() => <SignUpWithRedirect />}
                />
                <Route
                  exact
                  path={routes.SIGN_UP_EMAIL}
                  render={() => <SignUpForm />}
                />
                <Route
                  exact
                  path={routes.PASSWORD_FORGET}
                  render={() => <PasswordForget />}
                />
                <Route
                  exact
                  path={routes.PASSWORD_FORGET_RESET}
                  render={() => <PasswordForgetConfirm />}
                />
                <Route exact path={routes.SIGN_IN} render={() => <SignIn />} />
                <Route
                  exact
                  path={routes.SIGN_IN_EMAIL}
                  render={() => <SignInForm />}
                />
                <Route
                  exact
                  path={routes.PRIVACY}
                  render={() => (
                    <Privacy allowTests={allowTests} newUser={false} />
                  )}
                />
                <Route
                  exact
                  path={routes.PRIVACY_REGISTER}
                  render={() => (
                    <Privacy allowTests={allowTests} newUser={true} />
                  )}
                />
                <Route
                  exact
                  path={routes.CONTACT_US}
                  render={() => <ContactUs />}
                />
                <Route
                  exact
                  path={routes.SIGN_OUT}
                  render={() => <SignOut />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS}
                  render={() => <ManageProviders />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_FACEBOOK_DEEPLINK}
                  render={() => <AddSocialDeepLink type={FACEBOOK_PROVIDER} />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_GOOGLE_DEEPLINK}
                  render={() => <AddSocialDeepLink type={GOOGLE_PROVIDER} />}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_FACEBOOK_DISCONNECT_DEEPLINK}
                  render={() => (
                    <DisconnectSocialDeepLink type={FACEBOOK_PROVIDER} />
                  )}
                />
                <Route
                  exact
                  path={routes.MANAGE_PROVIDERS_GOOGLE_DISCONNECT_DEEPLINK}
                  render={() => (
                    <DisconnectSocialDeepLink type={GOOGLE_PROVIDER} />
                  )}
                />
                {/* Match all the pages below this Route to Page401 if there is a mainErrorPage */}
                {mainErrorPage && <Route render={() => <Page401 />} />}
                {/* Those Routes below will match only is mainErrorPage is false */}
                <Route
                  exact
                  path={routes.USER_DASHBOARD}
                  render={() => <UserDashboard />}
                />
                <Route
                  exact
                  path={routes.EMAIL_VERIFICATION}
                  render={() => <EmailVerification />}
                />
                <Route
                  exact
                  path={routes.EMAIL_VERIFICATION_EDIT}
                  render={() => <EmailVerificationEdit />}
                />
                <Route
                  exact
                  path={routes.VERIFY}
                  render={() => (
                    <Verify
                      sessionTimeout={
                        typeof urlQueryData.sessionTimeout !== 'undefined'
                          ? urlQueryData.sessionTimeout
                          : parseInt(sessionTimeout, 10)
                      }
                      sessionDbId={
                        typeof urlQueryData.sessionId !== 'undefined'
                          ? urlQueryData.sessionId
                          : null
                      }
                      providerToken={
                        typeof urlQueryData.providerToken !== 'undefined' &&
                        urlQueryData.providerToken !== 'vaplatformdefault'
                          ? urlQueryData.providerToken
                          : null
                      }
                      returnUrl={
                        typeof urlQueryData.return_to !== 'undefined'
                          ? urlQueryData.return_to
                          : process.env.REACT_APP_PLATFORM_URL
                      }
                      hydraChallenge={
                        urlQueryData.login_challenge
                          ? urlQueryData.login_challenge
                          : null
                      }
                      returnType={returnType}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.HYDRA_CONSENT}
                  render={() => <Consent urlQueryData={urlQueryData} />}
                />
                <Route
                  exact
                  path={routes.VERIFY_APP_VERIFICATION}
                  render={() => (
                    <VerifyAppVerification
                      returnType={returnType}
                      source={source}
                    />
                  )}
                />
                <Route
                  exact
                  path={routes.EMAIL_CONFIRM}
                  render={() => <EmailConfirm />}
                />
                {/* Last Route in Switch is used for generic 404 page */}
                <Route render={() => <Page404 />} />
              </Switch>
            </Container>
            <Logout />
          </ThemeProvider>
        </StyledEngineProvider>
      </>
    );
  }
};

export default App;
