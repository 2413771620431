import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useWesaluteRecaptcha } from 'hooks';
import { db } from 'actions';
import * as routes from 'constants/routes';
import Preloader from 'components/Preloader';
import PasswordForgetConfirmCodeForm from 'components/Sign/PasswordForgetConfirm/PasswordForgetConfirmCodeForm';
import staticTexts from 'texts';

var params = {};
window.location.search
  .substr(1)
  .split('&')
  .forEach(function (item) {
    params[item.split('=')[0]] = item.split('=')[1];
  });

const PasswordForgetConfirm = () => {
  const dispatch = useDispatch();
  const [recaptcha] = useWesaluteRecaptcha('password_reset');
  const { setErrorPage } = db;

  useEffect(() => {
    // Reset Password form.
    if (
      !params.oobCode ||
      (params.mode !== 'resetPassword' && params.mode !== 'verifyEmail')
    ) {
      // Wait for the maintenance error to clear, then show the error.
      setTimeout(
        () =>
          dispatch(
            setErrorPage(
              staticTexts.NewPasswordGeneralErrorTitle,
              staticTexts.NewPasswordGeneralErrorRequest,
              'error',
              false,
              staticTexts.NewPasswordGeneralErrorRequestButton,
              routes.SIGN_IN
            )
          ),
        500
      );
    }
    // eslint-disable-next-line
  }, [])

  if (!recaptcha) {
    return <Preloader title={staticTexts.NewPasswordLoading} />;
  } else {
    return <PasswordForgetConfirmCodeForm params={params} />;
  }
};

export default PasswordForgetConfirm;
