import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';
import Typography from '@mui/material/Typography';
import * as consumerRoutes from 'constants/routes';
import { GOOGLE_PROVIDER } from '../const';
import { onSetAuthUser } from 'actions/db';
import { getAppUrl } from 'actions/helpers';
import { APPS_DOMAIN } from 'constants/const';
import ContainerBox from 'components/Common/ContainerBox';
import { auth } from 'actions';
import { authRef } from 'firebase-config/firebase';

const styleOverride = {};
const DisconnectSocialDeepLink = ({ type }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const providerTitle = type === GOOGLE_PROVIDER ? 'Google' : 'Facebook';

  const authUser = useSelector((state) => state.sessionState.authUser);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  useEffect(() => {
    if (authUser === false) {
      history.push(consumerRoutes.SIGN_IN);
    } else {
      if (authUser?.providerData) {
        // Load auth providers.
        const providerData = authUser.providerData.filter((obj) => {
          return obj.providerId === type;
        });
        if (providerData?.[0]) {
          disconnectProvider();
        } else {
          setError(`Provider ${providerTitle} does not exist.`);
        }
      } else {
        setError(`Provider ${providerTitle} does not exist.`);
      }
    }
    // eslint-disable-next-line
	}, [authUser])

  const disconnectProvider = async () => {
    if (!authRef?.currentUser) {
      console.error('User is not loaded yet');
      return;
    }

    await auth
      .doUnlink(type)
      .then((user) => {
        dispatch(onSetAuthUser(user.toJSON()));
        setDeleted(true);
        if (window.opener) {
          const accountUrl =
            getAppUrl('account') || `https://account-local.${APPS_DOMAIN}`;
          window.opener.postMessage(
            { provider: `${type} account deleted` },
            accountUrl
          );
          window.close();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        console.error(error);
      });
  };

  return (
    <ContainerBox>
      {error ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {error}
        </Typography>
      ) : deleted ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {providerTitle} provider deleted from account
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.title} component="h1">
          Please wait until {providerTitle} provider deleted
        </Typography>
      )}
    </ContainerBox>
  );
};

export default DisconnectSocialDeepLink;
