import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import * as Sentry from '@sentry/browser';
import Typography from '@mui/material/Typography';
import * as consumerRoutes from 'constants/routes';
import { getAppUrl } from 'actions/helpers';
import { GOOGLE_PROVIDER } from 'components/ManageProviders/const';
import { APPS_DOMAIN } from 'constants/const';
import ContainerBox from 'components/Common/ContainerBox';
import {
  authHelper,
  google_provider,
  facebook_provider,
} from 'firebase-config/firebase';
import { auth } from 'actions';

const styleOverride = {};
const AddSocialDeepLink = ({ type }) => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [socialEmail, setSocialEmail] = useState(null);
  const [providerData, setProviderData] = useState(null);
  const providerInstance =
    type === GOOGLE_PROVIDER ? google_provider : facebook_provider;
  const providerStatic =
    type === GOOGLE_PROVIDER
      ? authHelper.GoogleAuthProvider
      : authHelper.FacebookAuthProvider;
  const providerTitle = type === GOOGLE_PROVIDER ? 'Google' : 'Facebook';

  const authUser = useSelector((state) => state.sessionState.authUser);
  const defaultDashboardStyles = useSelector(
    (state) => state.sessionDBState.data.defaultDashboardStyles
  );
  const customStyles = { ...defaultDashboardStyles, ...styleOverride };
  const useStyles = makeStyles(() => customStyles);
  const classes = useStyles();

  useEffect(() => {
    if (authUser === false) {
      history.push(consumerRoutes.SIGN_IN);
      return;
    }

    auth
      .doGetRedirectResult()
      .then((result) => {
        if (!result) {
          if (authUser?.providerData) {
            // Load auth providers.
            const existingProvider = authUser?.providerData?.find(
              (obj) => obj.providerId === type
            );
            if (!existingProvider) {
              addSocialProvider(authUser, providerInstance);
            } else {
              setProviderData(existingProvider);
            }
          } else {
            addSocialProvider(authUser, providerInstance);
          }
        } else {
          const credential = providerStatic.credentialFromResult(result);
          if (credential) {
            // Accounts successfully linked.
            const { email } = result?.user?.providerData?.filter(
              (provider) => provider.providerId === type
            )[0];
            setSocialEmail(email);
            if (window.opener) {
              const accountUrl =
                getAppUrl('account') || `https://account-local.${APPS_DOMAIN}`;
              window.opener.postMessage(
                { provider: `${type} account linked (email: ${email})` },
                accountUrl
              );
              window.close();
            }

            return email;
          } else {
            throw new Error('No credentials returned.');
          }
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(error.message);
        console.error(error);
      });

    // eslint-disable-next-line
	}, [authUser])

  const addSocialProvider = async (authUser, provider) => {
    if (authUser) {
      await auth.doLinkWithRedirect(provider).catch((error) => {
        Sentry.captureException(error);
        console.error(error);
        setError(error.message);
      });
    } else {
      const message = 'User object is not available yet';
      Sentry.captureException(message);
      console.log(message);
    }
  };

  return (
    <ContainerBox>
      {error ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {error}
        </Typography>
      ) : socialEmail ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {providerTitle} provider has been added (email: {socialEmail})
        </Typography>
      ) : providerData ? (
        <Typography variant="body2" className={classes.title} component="h1">
          {providerTitle} provider already exists for email:{' '}
          {providerData?.email}
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.title} component="h1">
          Please wait until redirected to {providerTitle} provider screen
        </Typography>
      )}
    </ContainerBox>
  );
};

export default AddSocialDeepLink;
