import { authHelper, authRef } from 'firebase-config/firebase';
import { api } from 'actions';
import * as Sentry from '@sentry/browser';
import * as routes from 'constants/routes';
import Cookies from 'js-cookie';
import {
  APPS_DOMAIN,
  AUTH_COOKIE_NAME,
  AUTH_COOKIE_NAME_OLD,
} from 'constants/const';

// Send Confirmation email.
export const reloadUser = () => authRef.currentUser.reload();

// Send Confirmation email.
export const sendConfirmationEmail = () =>
  authHelper.sendEmailVerification(authRef);

// Send Confirmation email.
export const confirmEmail = (actionCode) =>
  authHelper.applyActionCode(authRef, actionCode);

// Sign Up with email and password.
export const doCreateUserWithEmailAndPassword = (mail, password) =>
  authHelper.createUserWithEmailAndPassword(authRef, mail, password);

// Link with popup helper.
export const doLinkWithPopup = (provider) =>
  authHelper.linkWithPopup(authRef.currentUser, provider);

// Link with popup helper.
export const doLinkWithRedirect = (provider) =>
  authHelper.linkWithRedirect(authRef.currentUser, provider);

// Get redirect result.
export const doGetRedirectResult = () => authHelper.getRedirectResult(authRef);

// Unlink helper.
export const doUnlink = (type) => authHelper.unlink(authRef.currentUser, type);

// Update email helper.
export const updateEmail = (email) => {
  return authHelper.updateEmail(authRef.currentUser, email);
};

// Sign In with email and password.
export const doSignInWithEmailAndPassword = (mail, password) => {
  return authHelper.signInWithEmailAndPassword(authRef, mail, password);
};

// Sign in with redirect.
export const doSignInWithRedirect = (provider) =>
  authHelper.signInWithRedirect(authRef, provider);

// Sign In With Token.
export const doSignInWithCustomToken = (token) =>
  authHelper.signInWithCustomToken(authRef, token);

// Set up test recaptcha.
export const doAllowTests = () =>
  (authRef.settings.appVerificationDisabledForTesting = true);

// Sign out.
export const doSignOut = async () => {
  if (!authRef?.currentUser?.uid) {
    return;
  }
  return api
    .deleteToken()
    .then(() => {
      // Unset cookies for local development .wesalute.com.
      const options = { expires: 1, path: '/', domain: `.${APPS_DOMAIN}` };
      Cookies.remove(AUTH_COOKIE_NAME, options);
      Cookies.remove(AUTH_COOKIE_NAME_OLD, options);
      return authHelper.signOut(authRef);
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error(error);
    });
};

// Password Reset.
export const doPasswordReset = (mail) =>
  authHelper.sendPasswordResetEmail(authRef, mail);

export const verifyPasswordResetCode = (actionCode) =>
  authHelper.verifyPasswordResetCode(authRef, actionCode);

export const doDeleteCurrentUser = () => authRef.currentUser.delete();

export const confirmPasswordReset = (actionCode, newPass) => {
  return authHelper.confirmPasswordReset(authRef, actionCode, newPass);
};

export const updatePassword = (password) => {
  return authHelper.updatePassword(authRef, password);
};

export const updateProfile = (user, data) => {
  return authHelper.updateProfile(user, data);
};

// Process Firebase Auth providers for an email.
export const processSignInMethodsForEmail = (
  email,
  staticTexts,
  dispatch,
  setErrorPage,
  error
) => {
  // Prepare General message for already existing account.
  let message = staticTexts.FacebookErrorExistingAccountGeneralMessage
    ? staticTexts.FacebookErrorExistingAccountGeneralMessage
    : 'An account already exists with the same email address but @provider sign-in credentials. Sign in using a provider associated with this email address.';
  let buttonText = 'Sign In with Email';

  // If the error is for too many requests, return the error.
  if (error.code === 'auth/too-many-requests') {
    return new Promise((resolve) => {
      resolve(false);
    });
  }

  // Fetch the sign in providers for the email.
  return authHelper
    .fetchSignInMethodsForEmail(authRef, email)
    .then((providers) => {
      if (providers.length !== 0) {
        // Process users with Google and Email/Password providers.
        if (
          providers.includes('google.com') &&
          providers.includes('password')
        ) {
          message = message.replace('@provider', 'Google and Email');
          buttonText = staticTexts.FacebookErrorExistingAccountGoogleEmailButton
            ? staticTexts.FacebookErrorExistingAccountGoogleEmailButton
            : 'Sign In with Google or Email';
          dispatch(
            setErrorPage(
              staticTexts.GeneralErrorTitle,
              message,
              'error',
              false,
              buttonText,
              routes.SIGN_IN
            )
          );
        } else if (providers.includes('google.com')) {
          // Process users with Google provider.
          message = message.replace('@provider', 'Google');
          buttonText = staticTexts.FacebookErrorExistingAccountGoogleButton
            ? staticTexts.FacebookErrorExistingAccountGoogleButton
            : 'Sign In with Google';
          dispatch(
            setErrorPage(
              staticTexts.GeneralErrorTitle,
              message,
              'error',
              false,
              buttonText,
              routes.SIGN_IN
            )
          );
        } else if (providers.includes('facebook.com')) {
          // Process users with Facebook provider.
          message = message.replace('@provider', 'Facebook');
          buttonText = staticTexts.FacebookErrorExistingAccountFacebookButton
            ? staticTexts.FacebookErrorExistingAccountFacebookButton
            : 'Sign In with Facebook';
          dispatch(
            setErrorPage(
              staticTexts.GeneralErrorTitle,
              message,
              'error',
              false,
              buttonText,
              routes.SIGN_IN
            )
          );
        } else if (providers.includes('password')) {
          if (error.code === 'auth/wrong-password') {
            return false;
          }
          // Process users with Email provider.
          message = message.replace('@provider', 'Email');
          buttonText = staticTexts.FacebookErrorExistingAccountPasswordButton
            ? staticTexts.FacebookErrorExistingAccountPasswordButton
            : 'Sign In with Email';
          dispatch(
            setErrorPage(
              staticTexts.GeneralErrorTitle,
              message,
              'error',
              false,
              buttonText,
              routes.SIGN_IN_EMAIL
            )
          );
        }
        return true;
      }
      return false;
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error(error);
      dispatch(
        setErrorPage(
          staticTexts.GeneralErrorTitle,
          error.message,
          'error',
          false,
          staticTexts.GeneralErrorClose,
          false,
          false,
          true
        )
      );
    });
};

/**
 * Make Firebase error message mapped to human-readable analogue.
 *
 * @param error
 */
export const mapFirebaseError = (error) => {
  // Maps Firebase error to human readable analogue.
  switch (error.message) {
    case 'Firebase: Error (auth/wrong-password).':
      error.message = 'You have entered an invalid password';
      break;
    case 'Firebase: Error (auth/too-many-requests).':
      error.message =
        "You've had too many attempts to verify. Please contact customer support for further assistance.";
      break;
    case 'Firebase: Error (auth/invalid-verification-code).':
      error.message = 'Verification code entered is invalid. Please try again.';
      break;
    case 'Firebase: Error (auth/internal-error).':
      error.message =
        'An error has occurred. Please try again. If the issue persists, please contact customer support for further assistance.';
      break;
    case 'Firebase: Error (auth/network-request-failed).':
      error.message = 'Network request failed. Please try again.';
      break;
    case 'Firebase: Error (auth/argument-error).':
      error.message =
        'A browser error has occurred. If this issue persists, please try a different browser, or contact customer support for further assistance.';
      break;
    case 'Firebase: Error (auth/code-expired).':
      error.message = 'Verification code expired.';
      break;
    case 'Firebase: Error (auth/invalid-phone-number).':
      error.message = 'Invalid phone number.';
      break;
  }
};
